<template>
  <div class="main">
    <div>
      <Panel
        module="PDV"
        title="Locação"
        :showFilter="false"
        :showSearch="false"
        :showButtons="false"
      >
        <div slot="content-main">
          <br />
          <div>
            <b-row>
              <b-col class="text-center" sm="12">
                <div>
                  <span class="title">O QUE DESEJA FAZER?</span>
                </div>
                <br />
                <Button
                  _key="btnPdvCreate"
                  type="primary"
                  title="Emitir Locação"
                  classIcon="fas fa-plus-circle"
                  size="medium"
                  params="pdvRentCreate"
                  :clicked="navegateTo"
                />
                <Button
                  _key="btnPdvSearch"
                  color="white"
                  backGroundColor="#567EB3"
                  title="Procurar"
                  classIcon="fa-solid fa-magnifying-glass"
                  size="medium"
                  params="pdvSearch"
                  :clicked="navegateTo"
                />
                <Button
                  _key="btnPdvRentList"
                  type="edit"
                  title="Ver todas"
                  classIcon="fa-solid fa-bars"
                  size="medium"
                  params="pdvRentList"
                  :clicked="navegateTo"
                />
              </b-col>
            </b-row>
          </div>
        </div>
      </Panel>
    </div>
  </div>
</template>
<script>
import Panel from "@nixweb/nixloc-ui/src/component/layout/Panel";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapMutations } from "vuex";

export default {
  name: "PdvRentCreateUpdateView",
  components: {
    Panel,
    Button,
  },
  mounted() {
    this.removeLoading(["panel"]);
  },
  methods: {
    ...mapMutations("generic", ["removeLoading"]),
    navegateTo(routeName) {
      this.$router.push({
        name: routeName,
      });
    },
  },
};
</script>

<style scoped>
.title {
  color: #252422;
  margin: 0;
  text-align: center;
  font-size: 20px;
}
</style>